
import JsonDropzone from '@/components/ui/JsonDropzone.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { GoogleAssistantPlatformData, GoogleKeyFile, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type GoogleAssistantSettings = Pick<
  GoogleAssistantPlatformData,
  'enabled' | 'keyFile' | 'invocation'
>;

@Component({
  name: 'google-assistant-settings-card',
  components: { JsonDropzone },
})
export default class GoogleAssistantSettingsCard extends mixins(BotMixin) {
  googleAssistantSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.googleAssistantSettings, this.resetValue());
  }

  validatePotentialKeyFile = (obj: any) => {
    if (!obj.project_id || !obj.private_key || !obj.client_email) {
      return `Invalid file. The properties "project_id", "private_key" and "client_email" are required. Make sure this is a valid key-file from Google-Cloud. `;
    }
    return;
  };

  onFileInput(keyFile: GoogleKeyFile | null) {
    this.googleAssistantSettings.keyFile = keyFile;
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.GoogleAssistant,
        data: this.googleAssistantSettings,
      });
      this.$notify.success('Successfully updated Google Assistant-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Google Assistant-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  onRemoveKeyFile() {
    this.googleAssistantSettings.keyFile = null;
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.googleAssistantSettings = this.resetValue();
  }

  private resetValue(): GoogleAssistantSettings {
    return {
      enabled: this.bot.platformData.googleAssistant.enabled,
      keyFile: this.bot.platformData.googleAssistant.keyFile,
      invocation: this.bot.platformData.googleAssistant.invocation || '',
    };
  }
}
