
import GoogleAssistantSettingsCard from '@/components/google/GoogleAssistantSettingsCard.vue';
import GoogleAssistantUpdateActionButton from '@/components/google/GoogleAssistantUpdateActionButton.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-platform-google-assistant',
  components: { GoogleAssistantUpdateActionButton, GoogleAssistantSettingsCard },
})
export default class ProjectPlatformGoogleAssistant extends mixins(ProjectViewMixin) {}
