
import { GoogleAssistantApi } from '@/api/GoogleAssistantApi';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'google-assistant-update-action-button',
})
export default class GoogleAssistantUpdateActionButton extends mixins(BotMixin) {
  isLoading = false;
  async updateAction() {
    this.isLoading = true;
    try {
      await GoogleAssistantApi.updateAction(this.bot.id);
      this.$notify.success('Successfully updated Google Action');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Google Action failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
