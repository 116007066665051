import { Api, BOTS_BASE_URL } from '@/api/index';
import axios from 'axios';

export class GoogleAssistantApi {
  static async updateAction(botId: string): Promise<void> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/google-assistant/update-action`,
    });
    const result = await axios.request<void>(config);
    if (result.status === 204) {
      return;
    }
    throw new Error('Could not update action.');
  }
}
