
import AppFileDropzone from '@/components/ui/AppFileDropzone.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'json-dropzone',
  components: { AppFileDropzone },
})
export default class JsonDropzone extends Vue {
  @Prop({ required: false, type: Function, default: null })
  readonly validationFn!: ((obj: any) => string | Error | undefined) | null;

  isProcessing = false;

  private async processFile(file: File) {
    this.isProcessing = true;
    try {
      const result = await this.readAndParseJsonFile(file);
      if (this.validationFn) {
        const validationResult = this.validationFn(result);
        if (validationResult) {
          throw typeof validationResult === 'string'
            ? new Error(validationResult)
            : validationResult;
        }
      }
      this.$emit('input', result);
    } catch (e) {
      // TODO implement error handling if even necessary
      console.error(e);
    } finally {
      this.isProcessing = false;
    }
  }

  private readAndParseJsonFile(file: File): Promise<any> {
    if (file.type !== 'application/json') {
      throw new Error('Invalid file type. Has to be a JSON-file.');
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        if (event.target?.result) {
          return resolve(JSON.parse(event.target.result as string));
        }
        return reject(new Error('No data was loaded.'));
      };
      fileReader.onerror = (event) => {
        console.error('There was an error while importing.', event);
        return reject(event);
      };
      fileReader.readAsText(file, 'utf-8');
    });
  }
}
